export const islandAndMountainousArea = [
    '22386',
    '22387',
    '22388',
    '23004',
    '24005',
    '25006',
    '26007',
    '27008',
    '28009',
    '29010',
    '23100',
    '23101',
    '23102',
    '23103',
    '23104',
    '23105',
    '23106',
    '23107',
    '23108',
    '23109',
    '23110',
    '23111',
    '23112',
    '23113',
    '23114',
    '23115',
    '23116',
    '23124',
    '23125',
    '23126',
    '23127',
    '23128',
    '23129',
    '23130',
    '23131',
    '23132',
    '23133',
    '23134',
    '23135',
    '23136',
    '31708',
    '32133',
    '33411',
    '40200',
    '40201',
    '40202',
    '40203',
    '40204',
    '40205',
    '40206',
    '40207',
    '40208',
    '40209',
    '40210',
    '40211',
    '40212',
    '40213',
    '40214',
    '40215',
    '40216',
    '40217',
    '40218',
    '40219',
    '40220',
    '40221',
    '40222',
    '40223',
    '40224',
    '40225',
    '40226',
    '40227',
    '40228',
    '40229',
    '40230',
    '40231',
    '40232',
    '40233',
    '40234',
    '40235',
    '40236',
    '40237',
    '40238',
    '40239',
    '40240',
    '46768',
    '46769',
    '46770',
    '46771',
    '52570',
    '52571',
    '53031',
    '53032',
    '53033',
    '53089',
    '53090',
    '53091',
    '53092',
    '53093',
    '53094',
    '53095',
    '53096',
    '53097',
    '53098',
    '53099',
    '53100',
    '53101',
    '53102',
    '53103',
    '53104',
    '54000',
    '56347',
    '56348',
    '56349',
    '57068',
    '57069',
    '58760',
    '58761',
    '58762',
    '58800',
    '58801',
    '58802',
    '58803',
    '58804',
    '58805',
    '58806',
    '58807',
    '58808',
    '58809',
    '58810',
    '58816',
    '58817',
    '58818',
    '58826',
    '58828',
    '58829',
    '58830',
    '58831',
    '58832',
    '58833',
    '58834',
    '58835',
    '58836',
    '58837',
    '58838',
    '58839',
    '58840',
    '58841',
    '58842',
    '58843',
    '58844',
    '58845',
    '58846',
    '58847',
    '58848',
    '58849',
    '58850',
    '58851',
    '58852',
    '58853',
    '58854',
    '58855',
    '58856',
    '58857',
    '58858',
    '58859',
    '58860',
    '58861',
    '58862',
    '58863',
    '58864',
    '58865',
    '58866',
    '58953',
    '58954',
    '58955',
    '58956',
    '58957',
    '58958',
    '59102',
    '59103',
    '59106',
    '59127',
    '59129',
    '59137',
    '59138',
    '59139',
    '59140',
    '59141',
    '59142',
    '59143',
    '59144',
    '59145',
    '59146',
    '59147',
    '59148',
    '59149',
    '59150',
    '59151',
    '59152',
    '59153',
    '59154',
    '59155',
    '59156',
    '59157',
    '59158',
    '59159',
    '59160',
    '59161',
    '59162',
    '59163',
    '59164',
    '59165',
    '59166',
    '59421',
    '59531',
    '59551',
    '59563',
    '59568',
    '59650',
    '59766',
    '59781',
    '59782',
    '59783',
    '59784',
    '59785',
    '59786',
    '59787',
    '59788',
    '59789',
    '59790',
];
export const jejuArea = [
    '63000',
    '63001',
    '63002',
    '63003',
    '63004',
    '63005',
    '63006',
    '63007',
    '63008',
    '63009',
    '63010',
    '63011',
    '63012',
    '63013',
    '63014',
    '63015',
    '63016',
    '63017',
    '63018',
    '63019',
    '63020',
    '63021',
    '63022',
    '63023',
    '63024',
    '63025',
    '63026',
    '63027',
    '63028',
    '63029',
    '63030',
    '63031',
    '63032',
    '63033',
    '63034',
    '63035',
    '63036',
    '63037',
    '63038',
    '63039',
    '63040',
    '63041',
    '63042',
    '63043',
    '63044',
    '63045',
    '63046',
    '63047',
    '63048',
    '63049',
    '63050',
    '63051',
    '63052',
    '63053',
    '63054',
    '63055',
    '63056',
    '63057',
    '63058',
    '63059',
    '63060',
    '63061',
    '63062',
    '63063',
    '63064',
    '63065',
    '63066',
    '63067',
    '63068',
    '63069',
    '63070',
    '63071',
    '63072',
    '63073',
    '63074',
    '63075',
    '63076',
    '63077',
    '63078',
    '63079',
    '63080',
    '63081',
    '63082',
    '63083',
    '63084',
    '63085',
    '63086',
    '63087',
    '63088',
    '63089',
    '63090',
    '63091',
    '63092',
    '63093',
    '63094',
    '63095',
    '63096',
    '63097',
    '63098',
    '63099',
    '63100',
    '63101',
    '63102',
    '63103',
    '63104',
    '63105',
    '63106',
    '63107',
    '63108',
    '63109',
    '63110',
    '63111',
    '63112',
    '63113',
    '63114',
    '63115',
    '63116',
    '63117',
    '63118',
    '63119',
    '63120',
    '63121',
    '63122',
    '63123',
    '63124',
    '63125',
    '63126',
    '63127',
    '63128',
    '63129',
    '63130',
    '63131',
    '63132',
    '63133',
    '63134',
    '63135',
    '63136',
    '63137',
    '63138',
    '63139',
    '63140',
    '63141',
    '63142',
    '63143',
    '63144',
    '63145',
    '63146',
    '63147',
    '63148',
    '63149',
    '63150',
    '63151',
    '63152',
    '63153',
    '63154',
    '63155',
    '63156',
    '63157',
    '63158',
    '63159',
    '63160',
    '63161',
    '63162',
    '63163',
    '63164',
    '63165',
    '63166',
    '63167',
    '63168',
    '63169',
    '63170',
    '63171',
    '63172',
    '63173',
    '63174',
    '63175',
    '63176',
    '63177',
    '63178',
    '63179',
    '63180',
    '63181',
    '63182',
    '63183',
    '63184',
    '63185',
    '63186',
    '63187',
    '63188',
    '63189',
    '63190',
    '63191',
    '63192',
    '63193',
    '63194',
    '63195',
    '63196',
    '63197',
    '63198',
    '63199',
    '63200',
    '63201',
    '63202',
    '63203',
    '63204',
    '63205',
    '63206',
    '63207',
    '63208',
    '63209',
    '63210',
    '63211',
    '63212',
    '63213',
    '63214',
    '63215',
    '63216',
    '63217',
    '63218',
    '63219',
    '63220',
    '63221',
    '63222',
    '63223',
    '63224',
    '63225',
    '63226',
    '63227',
    '63228',
    '63229',
    '63230',
    '63231',
    '63232',
    '63233',
    '63234',
    '63235',
    '63236',
    '63237',
    '63238',
    '63239',
    '63240',
    '63241',
    '63242',
    '63243',
    '63244',
    '63245',
    '63246',
    '63247',
    '63248',
    '63249',
    '63250',
    '63251',
    '63252',
    '63253',
    '63254',
    '63255',
    '63256',
    '63257',
    '63258',
    '63259',
    '63260',
    '63261',
    '63262',
    '63263',
    '63264',
    '63265',
    '63266',
    '63267',
    '63268',
    '63269',
    '63270',
    '63271',
    '63272',
    '63273',
    '63274',
    '63275',
    '63276',
    '63277',
    '63278',
    '63279',
    '63280',
    '63281',
    '63282',
    '63283',
    '63284',
    '63285',
    '63286',
    '63287',
    '63288',
    '63289',
    '63290',
    '63291',
    '63292',
    '63293',
    '63294',
    '63295',
    '63296',
    '63297',
    '63298',
    '63299',
    '63300',
    '63301',
    '63302',
    '63303',
    '63304',
    '63305',
    '63306',
    '63307',
    '63308',
    '63309',
    '63310',
    '63311',
    '63312',
    '63313',
    '63314',
    '63315',
    '63316',
    '63317',
    '63318',
    '63319',
    '63320',
    '63321',
    '63322',
    '63323',
    '63324',
    '63325',
    '63326',
    '63327',
    '63328',
    '63329',
    '63330',
    '63331',
    '63332',
    '63333',
    '63334',
    '63335',
    '63336',
    '63337',
    '63338',
    '63339',
    '63340',
    '63341',
    '63342',
    '63343',
    '63344',
    '63345',
    '63346',
    '63347',
    '63348',
    '63349',
    '63350',
    '63351',
    '63352',
    '63353',
    '63354',
    '63355',
    '63356',
    '63357',
    '63358',
    '63359',
    '63360',
    '63361',
    '63362',
    '63363',
    '63364',
    '63365',
    '63366',
    '63367',
    '63368',
    '63369',
    '63370',
    '63371',
    '63372',
    '63373',
    '63374',
    '63375',
    '63376',
    '63377',
    '63378',
    '63379',
    '63380',
    '63381',
    '63382',
    '63383',
    '63384',
    '63385',
    '63386',
    '63387',
    '63388',
    '63389',
    '63390',
    '63391',
    '63392',
    '63393',
    '63394',
    '63395',
    '63396',
    '63397',
    '63398',
    '63399',
    '63400',
    '63401',
    '63402',
    '63403',
    '63404',
    '63405',
    '63406',
    '63407',
    '63408',
    '63409',
    '63410',
    '63411',
    '63412',
    '63413',
    '63414',
    '63415',
    '63416',
    '63417',
    '63418',
    '63419',
    '63420',
    '63421',
    '63422',
    '63423',
    '63424',
    '63425',
    '63426',
    '63427',
    '63428',
    '63429',
    '63430',
    '63431',
    '63432',
    '63433',
    '63434',
    '63435',
    '63436',
    '63437',
    '63438',
    '63439',
    '63440',
    '63441',
    '63442',
    '63443',
    '63444',
    '63445',
    '63446',
    '63447',
    '63448',
    '63449',
    '63450',
    '63451',
    '63452',
    '63453',
    '63454',
    '63455',
    '63456',
    '63457',
    '63458',
    '63459',
    '63460',
    '63461',
    '63462',
    '63463',
    '63464',
    '63465',
    '63466',
    '63467',
    '63468',
    '63469',
    '63470',
    '63471',
    '63472',
    '63473',
    '63474',
    '63475',
    '63476',
    '63477',
    '63478',
    '63479',
    '63480',
    '63481',
    '63482',
    '63483',
    '63484',
    '63485',
    '63486',
    '63487',
    '63488',
    '63489',
    '63490',
    '63491',
    '63492',
    '63493',
    '63494',
    '63495',
    '63496',
    '63497',
    '63498',
    '63499',
    '63500',
    '63501',
    '63502',
    '63503',
    '63504',
    '63505',
    '63506',
    '63507',
    '63508',
    '63509',
    '63510',
    '63511',
    '63512',
    '63513',
    '63514',
    '63515',
    '63516',
    '63517',
    '63518',
    '63519',
    '63520',
    '63521',
    '63522',
    '63523',
    '63524',
    '63525',
    '63526',
    '63527',
    '63528',
    '63529',
    '63530',
    '63531',
    '63532',
    '63533',
    '63534',
    '63535',
    '63536',
    '63537',
    '63538',
    '63539',
    '63540',
    '63541',
    '63542',
    '63543',
    '63544',
    '63545',
    '63546',
    '63547',
    '63548',
    '63549',
    '63550',
    '63551',
    '63552',
    '63553',
    '63554',
    '63555',
    '63556',
    '63557',
    '63558',
    '63559',
    '63560',
    '63561',
    '63562',
    '63563',
    '63564',
    '63565',
    '63566',
    '63567',
    '63568',
    '63569',
    '63570',
    '63571',
    '63572',
    '63573',
    '63574',
    '63575',
    '63576',
    '63577',
    '63578',
    '63579',
    '63580',
    '63581',
    '63582',
    '63583',
    '63584',
    '63585',
    '63586',
    '63587',
    '63588',
    '63589',
    '63590',
    '63591',
    '63592',
    '63593',
    '63594',
    '63595',
    '63596',
    '63597',
    '63598',
    '63599',
    '63600',
    '63601',
    '63602',
    '63603',
    '63604',
    '63605',
    '63606',
    '63607',
    '63608',
    '63609',
    '63610',
    '63611',
    '63612',
    '63613',
    '63614',
    '63615',
    '63616',
    '63617',
    '63618',
    '63619',
    '63620',
    '63621',
    '63622',
    '63623',
    '63624',
    '63625',
    '63626',
    '63627',
    '63628',
    '63629',
    '63630',
    '63631',
    '63632',
    '63633',
    '63634',
    '63635',
    '63636',
    '63637',
    '63638',
    '63639',
    '63640',
    '63641',
    '63642',
    '63643',
    '63644',
];
